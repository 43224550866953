
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiShopDetail, apiShopAdd, apiShopEdit, apiSetMealLists, apiShopRegionSearch, apiShopGeocoderSearch, apiShopTypeLists } from '@/api/shop'
import { apiPartnerLists } from '@/api/partner'
import historyMeal from './components/history-meal.vue'
import AreaSelect from '@/components/area-select.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import { throttle } from '@/utils/util'
import { importCDN } from '@/utils/importCDN'
import config from '@/config'

@Component({
    components: {
        historyMeal,
        AreaSelect,
        MaterialSelect
    }
})
export default class ListsDetail extends Vue {
    /** S Data **/

    identity: number | null = null; // 当前编辑用户的身份ID

    // 门店类型列表
    shopTypeData: any = []

    // 合作商列表
    partnerData: any = []

    // 套餐列表数据
    setMealData: any = []

    // 地址搜索
    searchAddress: string = ''

    // 添加门店表单数据
    form: any = {
        name: '',                     // 名称
        shop_type_id: '',             // 门店类型ID
        partner_id: '',               // 合作商ID
        super_admin: '',              // 超级管理员账号
        super_password: '',           // 超级管理员密码
        super_password_confirm: '',   // 确认密码
        contact: '',                  // 联系人
        contact_mobile: '',           // 联系人手机号
        set_meal_id: '',              // 套餐ID
        expires_time: '',             // 到期时间
        province_id: '',              // 省
        city_id: '',                  // 市
        district_id: '',              // 区
        address: '',                  // 详细地址
        longitude: '',                // 经度
        latitude: '',                 // 纬度
        image: '',                    // 门店LOGO
        sms_num: 0,                  // 短信数量
        remark: '',                   // 备注
        status: 1,                    // 状态:0-关闭;1-正常;2-冻结
        shop_data: {},
        car_induce_position: 1, // 感应位置 1:起点 2:终点
    };

    // 表单校验
    rules = {
        name: [{ required: true, message: '请输入门店名称', trigger: 'blur' }],
        shop_type_id: [{ required: true, message: '请选择门店类型', trigger: ['blur', 'change'] }],
        partner_id: [{ required: true, message: '请选择合作商', trigger: ['blur', 'change'] }],
        super_admin: [
            { required: true, message: '请输入管理员账户', trigger: 'blur' }
        ],
        super_password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            {
                validator: (rule: object, value: string, callback: any) => {
                    !value ? callback(new Error('请输入密码')) : callback()
                },
                trigger: 'blur'
            }
        ],
        super_password_confirm: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            {
                validator: (rule: object, value: string, callback: any) => {
                    if (this.form.password) {
                        if (!value) callback(new Error('请再次输入密码'))
                        if (value !== this.form.password) { callback(new Error('两次输入密码不一致!')) }
                    }
                    callback()
                },
                trigger: 'blur'
            }
        ],
        contact: [{ required: true, message: '请输入联系人姓名', trigger: ['blur', 'change'] }],
        contact_mobile: [{ required: true, message: '请选择联系人号码', trigger: ['blur', 'change'] }],
        set_meal_id: [{ required: true, message: '请选择套餐', trigger: ['blur', 'change'] }],
        expires_time: [{ required: true, message: '请选择到期时间', trigger: ['blur', 'change'] }],
        province_id: [{ required: true, message: '请选择门店地址', trigger: ['blur', 'change'] }],
        address: [{ required: true, message: '请输入详细地址', trigger: ['blur', 'change'] }],
        sms_num: [{ required: true, message: '请输入短信数量', trigger: ['blur', 'change'] }],
    };

    // 套餐-日期选择组件配置
    pickerOptions = {
        disabledDate(time: Date) {
            return time.getTime() < Date.now();
        }
    }

    // 腾讯地图对象
    tencentMap: any = undefined
    // 腾讯地图锚点对象
    markerLayer: any = undefined

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) return

            if (!this.identity) {
                this.handleShopAdd()
            } else {
                this.handleShopEdit()
            }
        })
    }

    // 添加门店
    async handleShopAdd(): Promise<void> {
        await apiShopAdd({ ...this.form })
        setTimeout(() => this.$router.go(-1), 500)
    }

    // 编辑门店
    async handleShopEdit(): Promise<void> {
        await apiShopEdit({ ...this.form, id: this.identity })
        setTimeout(() => this.$router.go(-1), 500)
    }

    // 获取详情
    async getShopDetailFunc(): Promise<void> {
        const res: any = await apiShopDetail({
            id: this.identity as number
        })
        this.form.super_admin = res.account
        Object.keys(res).map((key) => {
            if (res[key] != 0) {
                this.$set(this.form, key, res[key])
            }
        })

        this.$nextTick(() => {
            const TencentMap = (window as any).TMap
            this.tencentMap?.setCenter({ lat: res.latitude, lng: res.longitude })
            this.markerLayer?.updateGeometries([
                {
                    styleId: 'me',
                    id: '1',
                    position: new TencentMap.LatLng(res.latitude, res.longitude)
                }
            ])
        })
    }

    // 获取门店类型列表
    async getShopTypeLists(): Promise<void> {
        const { lists } = await apiShopTypeLists({
            page_size: 100
        })
        this.shopTypeData = lists
    }

    // 获取合作商列表
    async getPartnerLists(): Promise<void> {
        const { lists } = await apiPartnerLists({
            page_size: 100
        })
        this.partnerData = lists
    }

    // 获取套餐列表
    async getSetMealLists(): Promise<void> {
        const { lists } = await apiSetMealLists({ status: 1, page_size: 100 })
        this.setMealData = lists
    }


    // 初始化腾讯地图
    initTencentMap() {
        const TencentMap = (window as any).TMap

        if (!this.identity) {
            this.form.latitude = '39.954104'
            this.form.longitude = '116.357503'

            this.getCurrentLocation().then((data: any) => {
                this.form.latitude = data.lat
                this.form.longitude = data.lng

                this.tencentMap.setCenter({ lat: data.lat, lng: data.lng })
                this.markerLayer.updateGeometries([
                    {
                        styleId: 'me',
                        id: '1',
                        position: new TencentMap.LatLng(data.lat, data.lng)
                    }
                ])
            })
            console.log(123)
        }

        const latLng = {
            lat: this.form.latitude,
            lng: this.form.longitude
        }

        //定义地图中心点坐标
        const center = new TencentMap.LatLng(latLng.lat, latLng.lng)

        //定义map变量，调用 TencentMap.Map() 构造函数创建地图
        this.tencentMap = new TencentMap.Map(document.getElementById('tencent-map'), {
            center: center, //设置地图中心点坐标
            zoom: 17.2, //设置地图缩放级别
            pitch: 43.5, //设置俯仰角
            rotation: 45 //设置地图旋转角度
        })

        //创建并初始化MultiMarker
        this.markerLayer = new TencentMap.MultiMarker({
            map: this.tencentMap, //指定地图容器
            styles: {
                //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                me: new TencentMap.MarkerStyle({
                    width: 34, // 点标记样式宽度（像素）
                    height: 34, // 点标记样式高度（像素）
                    src: require('@/assets/images/icon_marker.png'), //图片路径
                    //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                    anchor: { x: 17, y: 34 }
                })
            },
            //点标记数据数组
            geometries: [
                {
                    //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                    id: '1',
                    //指定样式id
                    styleId: 'me',
                    //点标记坐标位置
                    position: new TencentMap.LatLng(latLng.lat, latLng.lng)
                    // properties: {
                    //     //自定义属性
                    //     title: 'marker',
                    // },
                }
            ]
        })

        // 监听地图点击事件
        this.tencentMap.on('click', ({ latLng, poi }: any) => {
            this.markerLayer.updateGeometries([
                {
                    styleId: 'me',
                    id: '1',
                    position: new TencentMap.LatLng(latLng.lat, latLng.lng)
                }
            ])

            this.form.latitude = latLng.lat
            this.form.longitude = latLng.lng

            this.onGeocoderSearch()
        })

        //
        //
    }

    // 导入腾讯地图API
    loadTencentMapScript() {
        const TENTCENT_MAP_API = `https://map.qq.com/api/gljs?v=1.exp&key=${config.tencentMapKey}`
        importCDN(TENTCENT_MAP_API).then(() => this.initTencentMap())
    }

    // 获取当前位置
    getCurrentLocation() {
        return new Promise((resolve: Function, reject: Function) => {
            const URL = 'https://mapapi.qq.com/web/mapComponents/geoLocation/v/geolocation.min.js'

            importCDN(URL)
                .then(() => {
                    const QQ = (window as any).qq
                    const geolocation = new QQ.maps.Geolocation(config.tencentMapKey, 'admin')
                    geolocation.watchPosition((data: any) => {
                        geolocation.clearWatch()

                        resolve(data)
                    })
                })
                .catch(err => reject(err))
        })
    }

    onGeocoderSearch() {
        apiShopGeocoderSearch({
            location: this.form.latitude + ',' + this.form.longitude,
            key: config.tencentMapKey
        }).then(res => {
            console.log(res)
            this.form.address = res.result.address
        })
    }

    // 地图搜索
    onMapRegionSearch() {
        if (!this.tencentMap) {
            this.$message.error('地图未加载完成')
            return
        }
        if (!this.form.province_id) {
            this.$message.error('请选择门店地址')
            return
        }

        apiShopRegionSearch({
            keyword: encodeURI(this.searchAddress),
            boundary: `region(${this.form.district_id ?? this.form.city_id}, 0)`,
            key: config.tencentMapKey
        })
            .then(({ data }) => {
                if (!data.length) {
                    return this.$message.info(`没有找到“${this.searchAddress}”的相关地点`)
                }

                this.form.address = data[0].address

                const TencentMap = (window as any).TMap
                const geometries: any = []

                // 清除所有标点
                this.markerLayer.setGeometries([])
                // 添加自己位置的标点
                this.markerLayer.add([
                    {
                        id: '1',
                        styleId: 'me',
                        position: new TencentMap.LatLng(this.form.latitude, this.form.longitude)
                    }
                ])

                data.forEach((item: any) => {
                    const { lat, lng } = item.location
                    geometries.push({
                        id: item.id,
                        styleId: 'marker',
                        position: new TencentMap.LatLng(lat, lng),
                        properties: {
                            title: item.title
                        }
                    })
                })

                // 更改地图中心点
                const { lat, lng } = data[0].location
                this.tencentMap.setCenter({ lat, lng })
                // 添加标点
                this.markerLayer.add(geometries)
            })
            .catch((err: any) => {
                // this.$message.error('地图搜索出现了点问题，请重新尝试。')
            })
    }
    /** E Methods **/


    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query

        // 编辑模式：初始化数据
        this.identity = +query.id
        if (+query.id) {
            this.getShopDetailFunc()
        }

        this.onSubmit = throttle(this.onSubmit, 2000)
        this.getShopTypeLists()
        this.getPartnerLists()
        this.getSetMealLists()

        this.loadTencentMapScript()
            ; (window as any).handleSearchMap = (data: any) => { }
    }
    /** E Life Cycle **/
}
